import { TourProvider } from '@reactour/tour';
import moment from 'moment';
import 'moment/locale/it';
import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useNavigate } from 'react-router-dom';
import { useFullscreen } from 'react-use';
import LogoutAlert from './LogoutAlert';
import COLORS from './common/colors';
import { LOCAL_STORAGE_TOKEN_KEY, PUBLIC } from './common/constants';
import showNotification from './components/extras/showNotification';
import ThemeContext from './contexts/themeContext';
import useDarkMode from './hooks/useDarkMode';
import AsideRoutes from './layout/Aside/AsideRoutes';
import { LOADING_CONTENT } from './layout/Content/Content';
import Portal from './layout/Portal/Portal';
import Wrapper from './layout/Wrapper/Wrapper';
import { loginPagesMenu } from './menu';
import steps, { styles } from './steps';
import { useUserStore } from './stores/useUserStore';
import { IErrorType } from './types/error-type';
import { getOS } from './utils/helpers';
import OneSignal from 'react-onesignal';

moment.locale('ot');

const App = () => {
	getOS();

	const [logout, retrieveUser, user] = useUserStore((state) => [
		state.logout,
		state.retrieveUser,
		state.user,
	]);
	// const getShop = useShopStore((state) => state.getShop);
	const fetchUserLoading = useRef(false);
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const handleAutologin = async () => {
		if (fetchUserLoading.current) {
			return;
		}
		fetchUserLoading.current = true;
		try {
			setLoading(true);
			await retrieveUser();
		} catch (err: IErrorType | any) {
			showNotification(
				'Errore',
				'Sessione scadauta, aggiorna la pagina o effettua di nuovo la login',
				'danger',
			);
			logout();
			navigate(`/login`);
		} finally {
			setLoading(false);
		}
	};

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	useEffect(() => {
		if (window.location.pathname.includes('public')) {
			localStorage.setItem(PUBLIC, PUBLIC);
			localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_TOKEN_KEY);
		} else if (localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
			handleAutologin();
		} else {
			navigate(`/login`);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (window.location.pathname.includes('public')) {
		localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_TOKEN_KEY);
	} else if (
		!localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY) &&
		!window.location.pathname.includes(loginPagesMenu.login.path)
	) {
		navigate('/login');
	}

	return (
		<ThemeProvider theme={theme}>
			<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
				<div className='app'>
					{loading && LOADING_CONTENT}
					{!loading && (
						<>
							{user?.role === 'ADMIN' && <AsideRoutes key={'aside-route'} />}
							<Wrapper />
						</>
					)}
				</div>
				{/* <LogoutAlert /> */}
				<Portal id='portal-notification'>
					<ReactNotifications />
				</Portal>
			</TourProvider>
		</ThemeProvider>
	);
};

export default App;
