import { AxiosError, AxiosResponse } from 'axios';
import { LOCAL_STORAGE_TOKEN_KEY } from '../common/constants';
import showNotification from '../components/extras/showNotification';
import { IErrorType } from '../types/error-type';
import { ILoginProps } from '../types/login-props-type';
import { IUser } from '../types/user-type';
import { Network } from '../utils/Network';

interface IAuthenticationService {
	logout(arg0: string | null): Promise<void>;
	login: (props: ILoginProps) => Promise<AxiosResponse<Partial<IUser>, AxiosError>>;
	retrieveCustomer: (token: string) => Promise<AxiosResponse<Partial<IUser>, AxiosError>>;
	saveOneSignalId: (oneSignalToken: string) => Promise<void>;
}

const AuthenticationService: IAuthenticationService = {
	login: async ({ username, password }) => {
		const body = {
			username,
			password,
		};
		try {
			const response = await Network.post('/public/authentication/signin', body);
			localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, response.data.jwt);
			return await AuthenticationService.retrieveCustomer(response.data?.jwt);
		} catch (error: IErrorType | any) {
			showNotification('Errore', error.errorMessage, 'danger');
			throw new Error('Errore durante la login');
		}
	},
	retrieveCustomer: (token) => {
		try {
			const response = Network.get(`/public/authentication/checkJwt?jwt=${token}`, {
				headers: { Authorization: 'Bearer ' + token },
			});
			return response;
		} catch (e: IErrorType | any) {
			console.log('Errore chiamata retrieve use', e);
			throw new Error('Sessione scaduta');
		}
	},
	logout: async (token) => {
		try {
			await Network.get('/public/authentication/logout', { params: { jwt: token } });
			localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
		} catch (error: IErrorType | any) {
			showNotification('Errore', 'Errore generico, aggiorna la pagina', 'danger');
		}
	},
	saveOneSignalId: async (oneSignalToken) => {
		try {
			await Network.put(
				'/public/authentication/update-one-signal?oneSignalToken=' + oneSignalToken,
			);
		} catch (error: IErrorType | any) {
			console.error('Errore durante il salvataggio del token di OneSignal', error);
		}
	},
};

export default AuthenticationService;
