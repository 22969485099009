import { lazy } from 'react';
import {
	agencyPagesAppointment,
	agencyTemplateSms,
	dashboardPagesMenu,
	homePage,
	loginPagesMenu,
	messageUnreadPages,
	progressAgency,
	progressOperator,
	requestLeadPage,
	stripePages,
	unloggedPages,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import { RouteDefinition } from '../types/route-definition-type';

const LANDING = {
	HOME: lazy(() => import('../pages/presentation/agency/agency-home/AgencyHomePage')),
	HOMEPAGE: lazy(() => import('../layout/Page/Homepage')),
	AGENCY_LEAD_PAGE: lazy(
		() => import('../pages/presentation/customer/customer-home/CustomerLeadPage'),
	),
	DASHBOARD: lazy(() => import('../pages/presentation/admin/admin-lead/LeadPage')),
	SEARCH_LEAD: lazy(() => import('../pages/presentation/admin/admin-lead/LeadTable')),
	CREATE_TEMPLATE: lazy(
		() => import('../pages/presentation/admin/admin-template/TemplateCreatePage'),
	),
	UPDATE_TEMPLATE: lazy(
		() => import('../pages/presentation/admin/admin-template/TemplateUpdatePage'),
	),
	GENERIC_LANDING: lazy(
		() => import('../pages/presentation/admin/admin-template/TemplateLeadPage'),
	),
	TEMP_PAGE: lazy(() => import('../pages/presentation/admin/admin-template/TemplateTempPage')),
	AGENCY_PAGE: lazy(
		() => import('../pages/presentation/admin/admin-agency-create-view/AgencySignupPage'),
	),
	MESSAGE_UNREAD_PAGE: lazy(
		() => import('../pages/presentation/admin/admin-message-unread/MessagePage'),
	),
	CUSTOMER_REQUEST_LEAD_PAGE: lazy(
		() => import('../pages/presentation/customer/customer-request-lead/CustomerRequestLeadPage'),
	),
	OPERATORS_PAGE: lazy(
		() => import('../pages/presentation/admin/admin-operator/OperatoreSignupPage'),
	),
	GROUPS_PAGE: lazy(() => import('../pages/presentation/admin/admin-groups/GroupsPage')),
	REPORT_OPERATOR_PAGE: lazy(
		() => import('../pages/presentation/admin/admin-report/OperatorReportPage'),
	),
	PROGRESS_OPERATOR_PAGE: lazy(
		() => import('../pages/presentation/admin/operator-progress/OperatorProgressPage'),
	),
	AGENCY_REPORT_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-report/AgencyReportPage'),
	),
	AGENCY_TEMPLATE_SMS: lazy(
		() => import('../pages/presentation/agency/agency-template-sms/AgencyTemplateSmsPage'),
	),
	AGENCY_APPOINTMENTS: lazy(
		() => import('../pages/presentation/agency/agency-appuntamenti/AgencyAppointmentPage'),
	),
	AGENCY_ESITO: lazy(
		() => import('../pages/presentation/customer/customer-esiti/CustomerEsitiPage'),
	),
	AGENCY_PRATICHE_LEAD: lazy(
		() => import('../pages/presentation/agency/agency-pratiche/AgencyPratichePage'),
	),
	PRATICHE_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-pratiche/AgencyPraticheTablePage'),
	),
	MASSIVE_SMS_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-template-sms/AgencySendMassiveSmsPage'),
	),
	PAYMENT_PAGE: lazy(() => import('../pages/presentation/agency/agency-payment/PaymentChargePage')),
	UPLOAD_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-upload-leads/AgencyUploadLeadPage'),
	),
	UPLOADED_FILE_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-upload-leads/AgencyFileUploadedPage'),
	),
	AGENCY_SUPPORT_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-assistenza/AgencySupportPage'),
	),
	ADMIN_SUPPORT_PAGE: lazy(
		() => import('../pages/presentation/agency/agency-assistenza/AgencySupportAdminPage'),
	),
	COLLABORATOR_SIGNUP_PAGE: lazy(
		() => import('../pages/presentation/agency/collaborators/CollaboratorSignupPage'),
	),
	ADMIN_STATS: lazy(() => import('../pages/presentation/admin/admin-stats/AdminStatsPage')),
	COLLABORATOR_STATS: lazy(
		() => import('../pages/presentation/agency/collaborators/stats/CollaboratorStatsPage'),
	),
	SETTINGS: lazy(() => import('../pages/presentation/settings/SettingsPage')),
};
const AUTH = {
	PAYMENT_SUCCESS_PAGE: lazy(() => import('../pages/presentation/auth/PaymentSuccess')),
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

const presentation: RouteDefinition[] = [
	{
		id: 'dashboard',
		path: dashboardPagesMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
	},

	{
		id: 'leads-table',
		path: 'leads-table',
		element: <LANDING.SEARCH_LEAD />,
	},
	{
		id: 'groups-page',
		path: dashboardPagesMenu.groups.path,
		element: <LANDING.GROUPS_PAGE />,
	},

	{
		id: 'create-template',
		path: dashboardPagesMenu.template.path,
		element: <LANDING.CREATE_TEMPLATE />,
	},
	{
		id: 'update-template',
		path: dashboardPagesMenu.updateTemplate.path,
		element: <LANDING.UPDATE_TEMPLATE />,
	},

	{
		id: 'generic-landing',
		path: 'public/landing',
		element: <LANDING.GENERIC_LANDING />,
	},
	{
		id: 'temp-page',
		path: 'public/landing-temp/:key',
		element: <LANDING.TEMP_PAGE />,
	},
	{
		id: 'home',
		path: '/home',
		element: <LANDING.HOME />,
	},
	{
		id: 'agency-page',
		path: dashboardPagesMenu.agency.path,
		element: <LANDING.AGENCY_PAGE />,
	},
	{
		id: 'homepage',
		path: homePage.login.path,
		element: <LANDING.HOMEPAGE />,
	},
	{
		id: 'agency-lead',
		path: 'agency-lead',
		element: <LANDING.AGENCY_LEAD_PAGE />,
	},
	{
		id: 'home',
		path: '/',
		element: <LANDING.HOMEPAGE />,
	},
	{
		id: 'message-unread',
		path: messageUnreadPages.message.path,
		element: <LANDING.MESSAGE_UNREAD_PAGE />,
	},
	{
		id: 'customer-request-lead',
		path: requestLeadPage.requestLead.path,
		element: <LANDING.CUSTOMER_REQUEST_LEAD_PAGE />,
	},
	{
		id: 'operators',
		path: dashboardPagesMenu.operators.path,
		element: <LANDING.OPERATORS_PAGE />,
	},
	// {
	// 	id: 'reports',
	// 	path: dashboardPagesMenu.report.path,
	// 	element: <LANDING.REPORT_OPERATOR_PAGE />,
	// },
	{
		id: progressAgency.progress.id,
		path: progressAgency.progress.path,
		element: <LANDING.AGENCY_REPORT_PAGE />,
	},
	{
		id: progressOperator.progress.id,
		path: progressOperator.progress.path,
		element: <LANDING.PROGRESS_OPERATOR_PAGE />,
	},
	{
		id: agencyTemplateSms.templateSms.id,
		path: agencyTemplateSms.templateSms.path,
		element: <LANDING.AGENCY_TEMPLATE_SMS />,
	},
	{
		id: agencyPagesAppointment.appointment.id,
		path: agencyPagesAppointment.appointment.path,
		element: <LANDING.AGENCY_APPOINTMENTS />,
	},
	{
		id: 'agencyEsiti',
		path: 'esiti',
		element: <LANDING.AGENCY_ESITO />,
	},
	{
		id: 'praticheLead',
		path: 'pratiche/lead/:uuid',
		element: <LANDING.AGENCY_PRATICHE_LEAD />,
	},
	{
		id: 'pratiche',
		path: 'pratiche',
		element: <LANDING.PRATICHE_PAGE />,
	},
	{
		id: 'massive-sms',
		path: 'massive-sms',
		element: <LANDING.MASSIVE_SMS_PAGE />,
	},

	{
		id: '404',
		path: unloggedPages.page404.path,
		element: <AUTH.PAGE_404 />,
	},
	{
		id: 'payment-success',
		path: stripePages.paymentSuccess.path,
		element: <AUTH.PAYMENT_SUCCESS_PAGE />,
	},
	{
		id: stripePages.paymentCharge.id,
		path: stripePages.paymentCharge.path,
		element: <LANDING.PAYMENT_PAGE />,
	},
	{
		id: 'upload',
		path: 'upload-file',
		element: <LANDING.UPLOAD_PAGE />,
	},
	{
		id: 'login',
		path: loginPagesMenu.login.path,
		element: <Login />,
	},
	{
		id: 'file-uploaded',
		path: 'file-uploaded',
		element: <LANDING.UPLOADED_FILE_PAGE />,
	},
	{
		id: 'support',
		path: 'support',
		element: <LANDING.AGENCY_SUPPORT_PAGE />,
	},
	{
		id: 'admin-support',
		path: 'admin-support',
		element: <LANDING.ADMIN_SUPPORT_PAGE />,
	},
	{
		id: 'collaborator',
		path: 'collaborator',
		element: <LANDING.COLLABORATOR_SIGNUP_PAGE />,
	},
	{
		id: 'stats',
		path: 'stats',
		element: <LANDING.ADMIN_STATS />,
	},
	{
		id: 'collaborator/stats',
		path: 'collaborator/stats',
		element: <LANDING.COLLABORATOR_STATS />,
	},
	{
		id: 'settings',
		path: 'my-settings',
		element: <LANDING.SETTINGS />,
	},
];
const contents = [...presentation];

export default contents;
