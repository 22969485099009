export const LOCAL_STORAGE_USER_KEY = 'facit_authUsername';

export const LOCAL_STORAGE_TOKEN_KEY = '__token';

export const BASE_URL = 'https://crm.sileads.cloud:8080/';
// export const BASE_URL = 'http://localhost:8080/';

export const DEFAULT_TIMEOUT_REQUESTS = 30000;

export const DEFAULT_ITEMS_FOR_PAGE = 10;

export const INITIAL_PAGE_NUMBER = 0;

export const PUBLIC = '__public';

export const motivationalQuotes = [
	'La chiave del successo è iniziare ogni giorno con un obiettivo chiaro.',
	"Ogni lead è un'opportunità per creare un nuovo cliente.",
	'Non smettere mai di migliorare: il prossimo passo potrebbe essere il migliore.',
	'La perseveranza trasforma un lead in un risultato.',
	'Un grande team crea grandi opportunità.',
	'La tua determinazione oggi costruisce il successo di domani.',
	'Ogni lead ha un potenziale nascosto: scoprilo!',
	'Un cliente felice inizia con un lead ben gestito.',
	'La fiducia è il ponte tra un lead e un cliente fedele.',
	'Il miglior momento per agire è adesso.',
	'Ogni errore è una lezione verso il successo.',
	'La comunicazione efficace è la chiave per convertire un lead.',
	'Il successo non è un traguardo, è un viaggio continuo.',
	'Conoscere il cliente è la base per ogni vendita.',
	'Ogni lead è una possibilità di fare la differenza.',
	'Il lavoro di squadra divide il compito e moltiplica il successo.',
	'Un buon ascolto trasforma i lead in opportunità.',
	'L’energia e l’entusiasmo sono contagiosi: usa il tuo potere.',
	'Ogni piccolo progresso porta a grandi risultati.',
	'La passione per ciò che fai è visibile nei risultati.',
	'Focalizzati sulle soluzioni, non sui problemi.',
	'Un buon servizio inizia con un sorriso.',
	'La pazienza e la persistenza vincono ogni sfida.',
	'Ogni lead è un passo verso il tuo obiettivo più grande.',
	'La qualità del tuo lavoro determina la qualità dei tuoi risultati.',
	'Un cliente soddisfatto è il miglior investimento.',
	"Ogni giorno è un'opportunità per imparare e crescere.",
	'Il successo è una decisione quotidiana.',
	'L’integrità e l’onestà costruiscono fiducia nei tuoi clienti.',
	'Non vendere prodotti, offri soluzioni.',
	'Il tempo speso sui lead è tempo investito nel futuro.',
	'Ogni lead richiede attenzione personalizzata per diventare un cliente.',
	'L’entusiasmo è il carburante per un grande risultato.',
	'Un’azione oggi può creare un’opportunità domani.',
	'Il cambiamento porta crescita: abbraccialo.',
	'Ogni lead ha una storia: sii parte della soluzione.',
	'La costanza è la madre di tutte le conquiste.',
	'Il successo si nasconde nei dettagli.',
	'La fiducia si costruisce un passo alla volta.',
	'Un grande venditore è un grande ascoltatore.',
	'Non contare i lead: fai in modo che contino.',
	'La collaborazione genera idee migliori e risultati più grandi.',
	'Il cliente è il cuore di ogni strategia vincente.',
	"La passione è la differenza tra un lavoro e un'opera d'arte.",
	'Ogni no è un passo verso un sì.',
	'Costruire relazioni è più importante di chiudere una vendita.',
	'Ogni opportunità inizia con una chiamata o un incontro.',
	'Un buon follow-up è la chiave per il successo.',
	'La fiducia dei clienti è il tuo miglior asset.',
	'Un obiettivo chiaro rende ogni passo più semplice.',
];
