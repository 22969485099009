import classNames from 'classnames';
import { FC, useState } from 'react';
import { Navigate } from 'react-router-dom';
import * as Yup from 'yup';
import { shallow } from 'zustand/shallow';

import { useFormik } from 'formik';
import { LOCAL_STORAGE_TOKEN_KEY } from '../../../common/constants';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { useUserStore } from '../../../stores/useUserStore';
import logo from '../../../assets/img/logo.png';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
	return (
		<>
			<img src={logo} alt='logo' className='img-fluid' />
		</>
	);
};

const Login: FC = () => {
	const { login } = useUserStore((state) => ({ user: state.user, login: state.login }), shallow);
	const currentWindowPath = window.location.pathname;
	const { darkModeStatus } = useDarkMode();

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validationSchema: Yup.object({
			loginUsername: Yup.string().required('Inserisci la username'),
			loginPassword: Yup.string().required('Inserisci la password'),
		}),
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			try {
				await login({ username: values.loginUsername, password: values.loginPassword });
			} catch (err: any) {
				formik.setFieldError('loginUsername', err.message);
			} finally {
				setIsLoading(false);
			}
		},
	});

	const [isLoading, setIsLoading] = useState<boolean>(false);

	if (localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY)) {
		return <Navigate to='/homepage' />;
	}

	return (
		<PageWrapper isProtected={false} title='Login' className='bg-dark'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}></div>

								<LoginHeader />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup id='loginUsername' isFloating label='Username'>
											<Input
												autoComplete='username'
												type='text'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={formik.errors.loginUsername}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
										<FormGroup className='mt-3' id='loginPassword' isFloating label='Password'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='primary'
											className='w-100 py-3'
											isDisable={
												isLoading || !formik.values.loginUsername || !formik.values.loginPassword
											}
											onClick={formik.handleSubmit}>
											{isLoading && <Spinner isSmall inButton isGrow />}
											Accedi
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {};

export default Login;
