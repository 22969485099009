import React, { Component } from 'react';
import Page500 from './pages/presentation/auth/Page500';

interface ErrorBoundaryState {
	hasError: boolean;
}

interface ErrorBoundaryProps {
	children: React.ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: any) {
		// Aggiorna lo stato per mostrare l'interfaccia di fallback
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// Log dell'errore per debug
		console.error('Errore catturato:', error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// UI di fallback
			return <Page500 />;
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
