import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Humans from '../../../assets/img/wanna/susy/500error.png';
import Button from '../../../components/bootstrap/Button';
import { unloggedPages } from '../../../menu';

const Page500 = () => {
	return (
		<PageWrapper title={unloggedPages.page404.text}>
			<Page>
				<div className='row d-flex align-items-center h-100'>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<div className='text-primary fw-bold' style={{ fontSize: 'calc(3rem + 3vw)' }}>
							500
						</div>
						<div className='text-dark fw-bold' style={{ fontSize: 'calc(1.5rem + 1.5vw)' }}>
							C'è stato un errore, ritorna in homepage
						</div>
					</div>
					<div className='col-12 d-flex align-items-baseline justify-content-center'>
						<img src={Humans} alt='Humans' style={{ height: '50vh' }} />
					</div>
					<div className='col-12 d-flex flex-column justify-content-center align-items-center'>
						<Button
							className='px-5 py-3'
							color='primary'
							isLight
							icon='HolidayVillage'
							tag='a'
							href='/homepage'>
							Homepage
						</Button>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default Page500;
